import React, {useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"

import SEO from "../components/seo"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, Container} from "react-bootstrap";
import { navigate } from "gatsby"
import Footer from "../components/footer";
import {isMobile} from 'react-device-detect';

const IndexPage = () => {
  const [isHovering, setIsHovering] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-white.svg" }) {
        publicURL
      },
      doctor: file(relativePath: { eq: "doctor.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      parent: file(relativePath: { eq: "parent.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      allDataJson {
        nodes {
          splash_title
          to_pediatrician
          to_pediatrician_question
          to_jb
          to_camilla
          to_parent
          property_of
          tos_link_text
          tos_link_label
          support_link_text
        }
      }
    }
  `)
  let className = "p-2 m-3 bg-white shadow-lg col-landing col-doctor cursor-pointer";
  let secondClass = "p-2 m-3 bg-white shadow-lg col-landing-mod";
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    setIsHovering(!isHovering);
  };

  const goto = (isJb) => {
    if(isJb) {
      navigate("/pediatra", { state: { isJb: isJb } })
    } else {
      navigate("/pediatraCamilla")
    }

  };


  const renderInMobile = () => {
    if(isMobile){
      return <Row className={"bg-dark justify-content-center pb-5"}>
        <Col xs={10} md={5} className={ !isHovering ? className: secondClass }  onClick={handleClick} >
          {!isHovering && (<div className={"align-items-center h-100"}>
                <Row className={"align-items-center h-100"}>
                  <Col>
                    <h2 className={"font-weight-bold text-blue ml-5 d-flex align-items-center"}><i className="fas fa-chevron-left"/> <div className={"ml-4"}>{data.allDataJson.nodes[0].to_pediatrician}</div></h2>
                  </Col>
                </Row>
              </div>
          )}

          {isHovering && (<div className={"align-items-center h-100"}>
            <h3 className={"mt-4 text-blue text-center p-4"}>{data.allDataJson.nodes[0].to_pediatrician_question}</h3>
            <Row className={"align-items-center justify-content-center h-25"}>
              <Button onClick={() => goto(true)} className={"font-weight-bold button-text-size text-center size-modified text-white bg-blue p-2 m-3 box-shadow col-landing-mod-button asd"}>
                {data.allDataJson.nodes[0].to_jb}
              </Button>
            </Row>
            <Row className={"align-items-center justify-content-center h-25"}>
              <Button onClick={() => goto(false)} className={"font-weight-bold button-text-size text-center size-modified text-white bg-blue p-2 m-3 box-shadow col-landing-mod-button asd"}>
                {data.allDataJson.nodes[0].to_camilla}
              </Button>
            </Row>
          </div>)}
        </Col>
        <Col xs={10} md={5} className={"p-2 m-3 bg-white shadow-lg col-landing col-parent cursor-pointer"}>
          <Link to={"/genitori"}>
            <Row className={"align-items-center justify-content-end h-100"}>
              <Col className={"text-end"}>
                <h2 className={"font-weight-bold text-blue mr-5 d-flex align-items-center"}><div className={"mr-4"}>{data.allDataJson.nodes[0].to_parent}</div> <i className="fas fa-chevron-right"/></h2>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
    } else {
      return <Row className={"bg-dark justify-content-center pb-5"}>
        <Col xs={10} md={5} className={ !isHovering ? className: secondClass }  onPointerOver={handleMouseEnter} onPointerLeave={handleMouseOut} >
          {!isHovering && (<div className={"align-items-center h-100"}>
                <Row className={"align-items-center h-100"}>
                  <Col>
                    <h2 className={"font-weight-bold text-blue ml-5 d-flex align-items-center"}><i className="fas fa-chevron-left"/> <div className={"ml-4"}>{data.allDataJson.nodes[0].to_pediatrician}</div></h2>
                  </Col>
                </Row>
              </div>
          )}

          {isHovering && (<div className={"align-items-center h-100"}>
            <h3 className={"mt-4 text-blue text-center p-4"}>{data.allDataJson.nodes[0].to_pediatrician_question}</h3>
            <Row className={"align-items-center justify-content-center h-25"}>
              <Button onClick={() => goto(true)} className={"font-weight-bold button-text-size text-center size-modified text-white bg-blue p-2 m-3 box-shadow col-landing-mod-button asd"}>
                {data.allDataJson.nodes[0].to_jb}
              </Button>
            </Row>
            <Row className={"align-items-center justify-content-center h-25"}>
              <Button onClick={() => goto(false)} className={"font-weight-bold button-text-size text-center size-modified text-white bg-blue p-2 m-3 box-shadow col-landing-mod-button asd"}>
                {data.allDataJson.nodes[0].to_camilla}
              </Button>
            </Row>
          </div>)}
        </Col>
        <Col xs={10} md={5} className={"p-2 m-3 bg-white shadow-lg col-landing col-parent cursor-pointer"}>
          <Link to={"/genitori"}>
            <Row className={"align-items-center justify-content-end h-100"}>
              <Col className={"text-end"}>
                <h2 className={"font-weight-bold text-blue mr-5 d-flex align-items-center"}><div className={"mr-4"}>{data.allDataJson.nodes[0].to_parent}</div> <i className="fas fa-chevron-right"/></h2>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
    }
  }



  return (
    <Container fluid className={"landing bg-dark p-0"}>
      <SEO title="Home" />

      <div className={"page-wrapper container-fluid"}>
        <Row className={"bg-dark justify-content-center py-5"}>
          <Col xs={4} md={3} lg={2} xl={1}>
            <img alt={"MioPediatra"} src={data.logo.publicURL}/>
          </Col>
        </Row>

        <Row className={"bg-dark text-center text-white"}>
          <Col>
            <h1 className={"font-weight-light"}>{data.allDataJson.nodes[0].splash_title}</h1>
          </Col>
        </Row>
        {renderInMobile()}
      </div>

      <Footer/>
    </Container>
  )
}

export default IndexPage
